import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";

import App from './App';
import reportWebVitals from './reportWebVitals';

const wallets = [new PetraWallet(), new MartianWallet()];

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <AptosWalletAdapterProvider plugins={wallets} autoConnect={true}>
            <App />
        </AptosWalletAdapterProvider>
    </BrowserRouter>
);

reportWebVitals();
