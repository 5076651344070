import React from 'react';

import "./index.scss";

interface PageWrapperProps{
    children: React.ReactNode,
}

const PageWrapper:React.FC<PageWrapperProps> = ({children}) => {
    return (
        <div className="page-wrapper">
            {children}
        </div>
    );
};

export default PageWrapper;