import React, { useState } from 'react';
import { Form } from "react-final-form";
import { Aptos, Bool, U64, U8, MoveString, MoveVector, AccountAddress, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { InputTransactionData, useWallet } from "@aptos-labs/wallet-adapter-react";

import MainWrapper from "../../../components/MainWrapper";
import FormField from "../../../components/FormField";
import LinksModal from "../LinksModal";
import { genRanHex, getResourceAddress, fromHexString, required } from "../utils";
import { toastSuccess, toastError } from "../../../utils/toastSuccess";

import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { ReactComponent as Discord } from '../../../assets/discord.svg';
import { ReactComponent as Twitter } from '../../../assets/twitter.svg';
import { ReactComponent as OtherLink } from '../../../assets/link.svg';
import rocketOnStart from '../../../assets/on_create_rocket.png'

import './index.scss';

interface LinksObject {
    discord?: string;
    twitter?: string;
    other?: string;
}

interface FormProps {
    image: string;
    collection: string;
    description: string;
    supply: number;
    price: number;
    limit: number;
    // @ts-ignore
    discordLink?: string;
    // @ts-ignore
    twitterLink?: string;
    // @ts-ignore
    otherLink?: string;
    // @ts-ignore
    links?: LinksObject;
    [key: string]: string | number;
}

const Body: React.FC = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [linksView, setLinksView] = useState<boolean>(false);
    const [transactionInProgress, setTransactionInProgress] = useState<boolean>(false);
    const { account, signAndSubmitTransaction  } = useWallet();
    const aptosConfig = new AptosConfig({ network: Network.MAINNET });
    const aptos = new Aptos(aptosConfig);
    const seed: string = genRanHex(64);

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const changeLinksView = () => {
        setLinksView(prev => !prev);
    }

    const sendForm = (values: FormProps, form: any) => {
        const formData = new FormData();
        const { discordLink, twitterLink, otherLink, ...rest } = values;
        const linksObject: LinksObject = {};

        if (discordLink && discordLink.trim() !== '') {
            linksObject.discord = discordLink;
        }
        if (twitterLink && twitterLink.trim() !== '') {
            linksObject.twitter = twitterLink;
        }
        if (otherLink && otherLink.trim() !== '') {
            linksObject.other = otherLink;
        }

        if (!selectedFile) {
            toastError("Image is required");

            return;
        }

        for (const key in rest) {
            formData.append(key, `${rest[key]}`);
        }

        if (account?.address !== "" && account?.address !== "") {
            // @ts-ignore
            formData.append('owner', account?.address);
        }

        formData.append('seed', seed);
        formData.append('img', selectedFile);
        formData.append('links', JSON.stringify(linksObject));

        axios.post('https://api.apstation.net/create_collection', formData)
            .then(response => {
                setTimeout(() => navigate(`/mint/${response.data.collection_address}`), 3000);
            })
            .catch(err => console.log(err))

        form.reset();
    };

    const createCollection = async (values: FormProps, form: any) => {

        if (!account) {
            toastError("Connect your wallet");
            return [];
        }

        if (!selectedFile) {
            toastError("Image is required");
            return;
        }

        if (isNaN(values.supply) || isNaN(values.price) || isNaN(values.limit)) {
            toastError("Supply, Price, and Limit per user must be numbers");
            return;
        }

        setTransactionInProgress(true);

        // @ts-ignore
        const ser_seed: MoveVector<U8> = new MoveVector<U8>(fromHexString(seed.slice(2)));
        const collectionName: MoveString = new MoveString(values.collection);
        const baseTokenName: MoveString = new MoveString(values.collection + " ");
        const collectionDesc: MoveString = new MoveString(values.description);
        // @ts-ignore
        const collectionAddress: string = getResourceAddress(account?.address, seed);
        const uri: MoveString = new MoveString(`https://api.apstation.net/md/${collectionAddress}.json`)
        const rn: U64 = new U64(1);
        const rd: U64 = new U64(100);
        const phases_amount: U64 = new U64(1);
        const is_public: MoveVector<Bool> = new MoveVector<Bool>([new Bool(true)]);
        const names: MoveVector<MoveString> = new MoveVector<MoveString>([new MoveString("Public")]);
        const mint_prices: MoveVector<U64> = new MoveVector<U64>([new U64(Math.round(values.price*100000000))])
        const mint_limits: MoveVector<U64> = new MoveVector<U64>([new U64(values.supply)]);
        const mints_per_users: MoveVector<U64> = new MoveVector<U64>([new U64(values.limit)]);
        const start_times: MoveVector<U64> = new MoveVector<U64>([new U64(Math.round(Date.now() / 1000) + 600)]);
        const whitelists: MoveVector<MoveVector<AccountAddress>> = new MoveVector<MoveVector<AccountAddress>>([new MoveVector<AccountAddress>([])])
        const moduleAddress = "0x35d780fc7ebfcb968aff429a742aa3839fd9c7e3c3edbbf7ca56559bef59487b";

        const transaction:InputTransactionData = {
            data: {
                function:`${moduleAddress}::test::createV1`,
                functionArguments:[
                    ser_seed, collectionName, baseTokenName,collectionDesc, uri, rn, rd, phases_amount, is_public, names, mint_prices, mint_limits,
                    mints_per_users, start_times, whitelists
                ]
            }
        }
        try {
            const response = await signAndSubmitTransaction(transaction);
            const res = await aptos.waitForTransaction({transactionHash:response.hash});
            if (res.success) {
                toastSuccess("+10 points");
                sendForm(values, form);
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setTransactionInProgress(false);
        }
    };

    return (
        <MainWrapper heading="Create collection">
            <ToastContainer style={{ width: "330px" }} />
            <Form
                onSubmit={(values: FormProps, form) => createCollection(values, form)}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} className="create-form">
                        {linksView && <LinksModal closeModal={changeLinksView}/>}
                        <div className="img-wrapper">
                            <Logo />
                            <div className="file-upload">
                                <label>
                                    <input type="file" onChange={handleFileChange} name="photo" />
                                    <span>{selectedFile === null ? "Load image" : "Image added"}</span>
                                </label>
                            </div>
                        </div>

                        <div className="fields-wrapper">
                            <FormField
                                name="collection"
                                validators={required}
                                type="text"
                                placeholder="Name of collection"
                                component="input"
                            />
                            <FormField
                                name="description"
                                component="textarea"
                                placeholder="Description"
                                className="desc"
                                validators={required}
                            />

                            <div className="fields-wrapper-details-block">
                                <div className="details">
                                    <div className="details-detail">
                                        <span>Supply</span>
                                        <FormField name="supply" validators={required} type="text"  component="input"/>
                                    </div>
                                    <div className="details-detail">
                                        <span>Price</span>
                                        <FormField name="price" validators={required} type="text" component="input" placeholder="APT"/>
                                    </div>
                                    <div className="details-detail">
                                        <span>Limit per user</span>
                                        <FormField name="limit" validators={required} type="text" component="input"/>
                                    </div>
                                </div>

                                <div className="connection" onClick={changeLinksView}>
                                    <span>Connect</span>
                                    <div className="connection-links">
                                        <Discord />
                                        <Twitter />
                                        <OtherLink />
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="send-form-btn">Create (+10 points)</button>
                        </div>
                    </form>
                )}
            />

            <img src={rocketOnStart} className="progressbar" alt="progressbar"/>

        </MainWrapper>
    )
};

export default Body;