import React from 'react';

import Header from "../../components/Header";
import OurCollection from "./CollectionList";
import PageWrapper from "../../components/PageWrapper";

const MintApstation: React.FC = () => {
    return (
        <PageWrapper>
             <Header />
             <OurCollection />
        </PageWrapper>
    );
};

export default MintApstation;