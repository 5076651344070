import React from 'react';
import { Link } from "react-router-dom";

import "./index.scss";

interface NavBlockProps {
    img: string,
    text: string,
    linkTo: string,
    disabled?: boolean
}

const NavBlock:React.FC<NavBlockProps> = ({ img, linkTo, text, disabled}) => {
    return (
        <Link to={linkTo} className={disabled ? "app-link disabled" : "app-link"}>
            <img src={img} alt=""/>
            <span>{text}</span>
        </Link>
    );
};

export default NavBlock;