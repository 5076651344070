import React from 'react';

interface MainWrapperProps {
    heading?: string,
    children: React.ReactNode,
}

const MainWrapper: React.FC<MainWrapperProps> = ({heading, children}) => {
    return (
        <div className="main-block">
            <h1 className="main-block-paragraph" style={{marginBottom: '40px'}}>{heading}</h1>
            {children}
        </div>
    );
};

export default MainWrapper;