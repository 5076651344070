import React from 'react';
import { Field } from "react-final-form";

import './index.scss';

interface Props {
    name: string,
    validators?: (value: any) => string | undefined,
    type?: string,
    placeholder?: string,
    component: 'input' | 'textarea',
    className?: string,
}

const FormField: React.FC<Props> = ({
        name,
        type,
        validators,
        placeholder,
        component,
        className,
        }) => {
    return (
        <Field name={name} validate={validators}>
            {({ input, meta }) => (
                <div className={`field-block ${meta.error && meta.touched ? 'error' : ''}`}>
                    {component === 'input' ? <input
                        {...input}
                        name={name}
                        className={`field-block-input ${meta.error && meta.touched ? 'error' : ''}`}
                        type={type}
                        placeholder={placeholder}
                    />:

                        <textarea
                            {...input}
                            name={name}
                            className={`field-block-input ${className} ${meta.error && meta.touched ? 'error' : ''}`}
                            placeholder={placeholder}
                        />}


                    {meta.error && meta.touched}
                </div>
            )}
        </Field>
    );
};

export default FormField;
