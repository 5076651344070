import React from 'react';
import Header from "../../components/Header";
import MintBody from "./Body";
import PageWrapper from "../../components/PageWrapper";

const Mint: React.FC = () => {
    return(
        <PageWrapper>
         <Header />
         <MintBody />
        </PageWrapper>
    );
};

export default Mint;