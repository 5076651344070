import React, {useEffect, useState} from 'react';
import {useWallet} from "@aptos-labs/wallet-adapter-react";
import axios from "axios";
import {Aptos, AptosConfig, Network} from "@aptos-labs/ts-sdk";
import {Link, useNavigate} from "react-router-dom";

import MainWrapper from "../../../components/MainWrapper";

import './index.scss';

interface NftPhases {
    mint_price: string;
}

interface NftResource {
    collection_name: string;
    phases: NftPhases[];
}

const UserCollection = () => {
    const [collectionList, setCollectionList] = useState([]);
    const { account } = useWallet();
    const aptosConfig = new AptosConfig({ network: Network.MAINNET });
    const aptos = new Aptos(aptosConfig);
    const navigate = useNavigate();

    const fetchNft = async (nftAddress: string) => {
        if (!account) return [];
        const moduleAddress = "0x35d780fc7ebfcb968aff429a742aa3839fd9c7e3c3edbbf7ca56559bef59487b";
        try {
            const nftResource = await aptos.getAccountResource(
                {
                    accountAddress:nftAddress!,
                    resourceType:`${moduleAddress}::test::CollectionV1Data`
                }
            );
            const response = await axios.get(nftResource.uri);
            const image = response.data.image;
            return { nftResource, image };
        } catch (e: any) {
            console.log(e);
        }
    };

    useEffect(() => {
        axios.get(`https://api.apstation.net/get_collection_of_user?user=${account?.address}`)
            .then(async (response) => {
                const res = await Promise.all(response.data.map(async (item: any) => {
                    const nft = await fetchNft(item);
                    return { ...nft, item };
                }));
                // @ts-ignore
                setCollectionList(res);
            });
    }, [account?.address]);

    return (
        <MainWrapper>
            { collectionList.length > 0 ?
                <div className="user-list-wrapper">
                    {collectionList.map(({ nftResource, item, image }: { nftResource: NftResource, item: string, image: string }) => (
                        <div key={nftResource.collection_name} className="user-collection">
                            <img src={image} alt="nft"/>
                            <div className="user-collection-info">
                                <span>{nftResource.collection_name}</span>
                                <span className="user-collection-info-price">
                                    {Number(nftResource.phases[0].mint_price)/100000000} APT
                                </span>
                            </div>
                            <Link to={`/mint/${item}`}>Mint</Link>
                        </div>
                    ))}
                </div>
                :
                <div style={{textAlign: "center"}}>
                    <Link
                        to='/create'
                        style={{
                            fontSize: "35px",
                            textDecoration: "none",
                            color: "white",
                            cursor: "pointer"
                        }}
                    >
                        Create NFT
                    </Link>
                </div>
            }

        </MainWrapper>
    );
};

export default UserCollection;