import React from 'react';

import Header from "../../components/Header";
import AppNav from "./AppNav";
import PageWrapper from "../../components/PageWrapper";

const Home: React.FC = () => {
    return (
        <PageWrapper>
            <Header hideNav />
            <AppNav />
        </PageWrapper>
    );
};

export default Home;