import React from 'react';
import { useWallet } from "@aptos-labs/wallet-adapter-react";

import MainWrapper from "../../../components/MainWrapper";
import NavBlock from "./NavBlock";

import addImg from '../../../assets/gg_add.png';
import mintImg from '../../../assets/lets-icons_size-down.png';
import fillLogo from '../../../assets/fill_logo.png';

import './index.scss';

const AppNav: React.FC = () => {
    const { account } = useWallet();

    return (
        <MainWrapper heading="Home">
            <div className="app-navigation">
               <NavBlock img={addImg} text="Create collection" linkTo="/create" />
               <NavBlock img={mintImg} text="Profile" linkTo="/profile" disabled={!account?.address} />
               <NavBlock img={fillLogo} text="ApStation: Zero" linkTo="/mint-apstation" />
            </div>
        </MainWrapper>
    );
};

export default AppNav;