import React from 'react';

import PageWrapper from "../../components/PageWrapper";
import Header from "../../components/Header";
import UserCollection from "./UserCollection";

const Profile = () => {
    return (
        <PageWrapper>
            <Header />
            <UserCollection />
        </PageWrapper>
    );
};

export default Profile;