import { U8 } from "@aptos-labs/ts-sdk";
import jsSHA from "jssha";

export const genRanHex = (size: number) =>
    "0x" + [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

export const getResourceAddress = (owner: string , seed: string) => {
    if (owner === "") {
        return;
    }

    const data = owner.slice(2).padStart(64, "0") + seed.slice(2).padStart(64, "0") + "ff";
    const hash = new jsSHA("SHA3-256", "HEX", {numRounds: 1});
    hash.update(data);
    return "0x" + hash.getHash("HEX");
};

export const fromHexString = (hexString: string) => {
    const matches = hexString.match(/.{1,2}/g);
    const arr: U8[] = [];
    if (matches !== null) {
        const t = Uint8Array.from(matches.map((byte) => parseInt(byte, 16)));
        t.forEach(elem => {
            arr.push(new U8(elem))
        })

        return arr;
    } else {
        return new Uint8Array(0);
    }
};

export const required = (value: string) => (value ? undefined : 'Required')
