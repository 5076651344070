import React from 'react';

import Header from "../../components/Header";
import Body from "./Body";
import PageWrapper from "../../components/PageWrapper";

const CreatePage: React.FC = () => {
    return (
        <PageWrapper>
          <Header />
          <Body />
        </PageWrapper>
    );
};

export default CreatePage;