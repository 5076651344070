import React, { useEffect, useState } from 'react';
import { InputTransactionData, useWallet } from "@aptos-labs/wallet-adapter-react";
import { AccountAddress, Aptos, AptosConfig, Network, U64 } from "@aptos-labs/ts-sdk";
import axios from "axios";
import { arrayify } from "@ethersproject/bytes";
import { ToastContainer } from "react-toastify";

import { toastError, toastSuccess } from "../../../utils/toastSuccess";

import './index.scss';

interface IUri {
    discord?: string,
    image: string,
    twitter?: string,
    website?: string
}

const OurCollection: React.FC = () => {
    const [transactionInProgress, setTransactionInProgress] = useState<boolean>(false);
    const [collectionData, setCollectionData] = useState({
        collection_name: "",
        collection_description: "",
        phases: [{
            mint_limit: 0,
            mint_price: 0,
            mints_per_user: 0,
            minted: 0,
            start_time: 0,
        }],
        uri: "",
    });
    const [uriData, setUriData] = useState<IUri>();

    const { account, signAndSubmitTransaction } = useWallet();
    const aptosConfig = new AptosConfig({ network: Network.MAINNET });
    const aptos = new Aptos(aptosConfig);

    useEffect(() => {
        fetchNft();
    }, [account?.address]);

    const calculateProgress = () => {
        const calculateWidth = (collectionData.phases[0].minted / collectionData.phases[0].mint_limit) * 100;
        return collectionData.phases[0].minted ? `${calculateWidth}%` : '0';
    };

    const fetchNft = async () => {
        const moduleAddress = "0x35d780fc7ebfcb968aff429a742aa3839fd9c7e3c3edbbf7ca56559bef59487b";
        try {
            const nftResource = await aptos.getAccountResource(
                {
                    accountAddress: "0x619f9967952b1e11aaa2d0ce265b287e92949925f0cf35e03dc68d4c4f94c317",
                    resourceType: `${moduleAddress}::test::CollectionV1Data`
                }
            );

            setCollectionData(nftResource);

            axios.get(`${nftResource.uri}`)
                .then(response => {
                    setUriData(response.data);
                })
                .catch(err => console.log(err));

        } catch (e: any) {
            console.log(e);
        }
    };

    const Mint = async () => {
        if (!account) {
            toastError("Connect Wallet");

            return;
        }
        setTransactionInProgress(true);

        // @ts-ignore
        const collectionAddress: AccountAddress = new AccountAddress(arrayify("0x619f9967952b1e11aaa2d0ce265b287e92949925f0cf35e03dc68d4c4f94c317"));
        const amount: U64 = new U64((1));
        const value: U64 = new U64((collectionData.phases[0].mint_price));
        const moduleAddress = "0x35d780fc7ebfcb968aff429a742aa3839fd9c7e3c3edbbf7ca56559bef59487b";

        const transaction: InputTransactionData = {
            data: {
                function: `${moduleAddress}::test::mint`,
                functionArguments: [
                    collectionAddress, amount, value
                ]
            }
        }
        try {
            const response = await signAndSubmitTransaction(transaction);
            const res = await aptos.waitForTransaction({ transactionHash: response.hash });
            if (res.success) {
                toastSuccess("+1500 points");
                await fetchNft();
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setTransactionInProgress(false);
        }
    };

    return (
        <div className="mint-apstation">
            <ToastContainer style={{ width: "330px" }} />
            <h1 style={{ fontSize: "45px" }}>Mint ApStation</h1>
            <div className="mint-apstation-info">
                <img src={uriData?.image} alt="img" style={{width: 400}}/>

                <div className="mint-apstation-info-details">
                    <div className="name-wrapper">
                        <span>{collectionData.collection_name}</span>
                    </div>

                    <div className="description">
                        <span>
                            {collectionData.collection_description}
                        </span>
                    </div>

                    <div className="progressbar-wrapper">
                        <div className="progressbar">
                            <div style={{
                                width: calculateProgress(),
                            }} className="progressbar-line">
                            </div>
                        </div>

                        <span style={{ textAlign: "right", fontFamily: 'dmSans' }}>
                            Progress: {collectionData.phases[0].minted} / {collectionData.phases[0].mint_limit}
                        </span>
                    </div>

                    <button
                        style={{ width: "85%" }}
                        onClick={Mint}
                        disabled={
                            collectionData.phases[0].minted === collectionData.phases[0].mint_limit
                        }
                    >
                        Mint (+1500 points)
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OurCollection;
