import React, { useState, useEffect } from 'react';
import { WalletSelector } from "@aptos-labs/wallet-adapter-ant-design";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Link } from "react-router-dom";
import { Aptos, AptosConfig, Network} from "@aptos-labs/ts-sdk";

import { ReactComponent as Logo } from '../../assets/logo.svg';

import './index.scss';

interface HeaderProps {
    hideNav?: boolean,
}

const Header: React.FC<HeaderProps> = ({ hideNav }) => {
    const [accountHasList, setAccountHasList] = useState<boolean>(false);
    const { account } = useWallet();
    const aptosConfig = new AptosConfig({ network: Network.MAINNET });
    const aptos = new Aptos(aptosConfig);
    const [points, setPoints] = useState({
        amount: "0",
    });

    useEffect(() => {
        fetchNft();
    }, [account?.address, ]);

    const fetchNft = async () => {
        if (!account) return [];
        const moduleAddress = "0x35d780fc7ebfcb968aff429a742aa3839fd9c7e3c3edbbf7ca56559bef59487b";

        try {
            const nftResource = await aptos.getAccountResource(
                {
                    accountAddress:account.address,
                    resourceType:`${moduleAddress}::test::Points`
                }
            );

            setAccountHasList(true);
            setPoints(nftResource);
        } catch (e: any) {
            setAccountHasList(false);
        }
    };

    return (
            <header>
                <div className="logo">
                    <Link to="/">
                        <Logo />
                    </Link>
                    <div>
                        <span style={{color: "#8747EE"}}>Ap</span>
                        <span>Station</span>
                    </div>
                </div>

                <div className="nav">
                    <ul className={hideNav ? "hide-nav" : "nav-links"}>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/mint-apstation">ApStation: Zero</Link></li>
                    </ul>

                    <span className="points">{accountHasList ? points.amount : 0} points</span>

                    <WalletSelector />
                </div>
            </header>
    );
};

export default Header;