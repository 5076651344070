import React from 'react';
import FormField from "../../../components/FormField";

import './index.scss';

interface Props {
    closeModal: () => void,
}

const LinksModal: React.FC<Props> = ({closeModal}) => {
    return (
        <div className="links-modal">
            <button onClick={closeModal} className="links-modal-close-btn">X</button>
            <div className="link-block">
                <span>Discord</span>
                <FormField name="discordLink" component="input" type="text"/>
            </div>
            <div className="link-block">
                <span>Twitter</span>
                <FormField name="twitterLink" component="input" type="text"/>
            </div>
            <div className="link-block">
                <span>Other link</span>
                <FormField name="otherLink" component="input" type="text"/>
            </div>
        </div>
    );
};

export default LinksModal;