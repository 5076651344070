import React, {useEffect, useState} from 'react';
import {InputTransactionData, useWallet} from "@aptos-labs/wallet-adapter-react";
import {AccountAddress, Aptos, AptosConfig, Network, U64} from "@aptos-labs/ts-sdk";
import {arrayify} from "@ethersproject/bytes";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import {ToastContainer} from "react-toastify";

import MainWrapper from "../../../components/MainWrapper";
import { toastSuccess, toastError } from "../../../utils/toastSuccess";

import {ReactComponent as Discord} from "../../../assets/discord.svg";
import {ReactComponent as Twitter} from "../../../assets/twitter.svg";
import {ReactComponent as OtherLink} from "../../../assets/link.svg";
import rocketOnStart from "../../../assets/on_mint_rocket.png";

import './index.scss';

interface IUri {
    discord?: string,
    image: string,
    twitter?: string,
    website?: string
}

const MintBody: React.FC = () => {
    const { nftAddress } = useParams();
    const [isMintButtonActive, setIsMintButtonActive] = useState<boolean>(false);
    const [transactionInProgress, setTransactionInProgress] = useState<boolean>(false);
    const [mintedNft, setMintedNft] = useState<number>(0);
    const [uriData, setUriData] = useState<IUri>();
    const [collectionData, setCollectionData] = useState({
        collection_name: "",
        collection_description: "",
        phases: [{
            mint_limit: 0,
            mint_price: 0,
            mints_per_user: 0,
            minted: 0,
            start_time: 0,
        }],
        uri: "",
    });

    const { account, signAndSubmitTransaction } = useWallet();
    const aptosConfig = new AptosConfig({ network: Network.MAINNET });
    const aptos = new Aptos(aptosConfig);

    // Добавляем состояние для отслеживания оставшегося времени
    const [remainingTime, setRemainingTime] = useState<number | null>(null);

    useEffect(() => {
        fetchNft();
        countOfMinted();
    }, [account?.address]);

    useEffect(() => {
        // Проверяем, прошло ли уже 10 минут после создания товара
        if (collectionData.phases.length > 0 && collectionData.phases[0].start_time > 0) {
            const currentTimeInSeconds = Math.round(Date.now() / 1000 + 600);
            const creationTimeInSeconds = collectionData.phases[0].start_time;
            const elapsedTimeInSeconds = currentTimeInSeconds - creationTimeInSeconds;

            if (elapsedTimeInSeconds < 600) {
                // Вычисляем оставшееся время только если прошло менее 10 минут
                setRemainingTime(600 - elapsedTimeInSeconds);

                // Устанавливаем интервал для обновления оставшегося времени каждую секунду
                const intervalId = setInterval(() => {
                    setRemainingTime((prevRemainingTime) => {
                        if (prevRemainingTime === null) {
                            clearInterval(intervalId);
                            setIsMintButtonActive(false); // Деактивируем кнопку Mint
                            return null;
                        }
                        if (prevRemainingTime <= 1) {
                            setIsMintButtonActive(true); // Активируем кнопку Mint, если таймер достиг нуля
                        }
                        return Math.max(0, prevRemainingTime - 1);
                    });
                }, 1000);

                // Очистка интервала при размонтировании компонента
                return () => clearInterval(intervalId);
            } else {
                setRemainingTime(null);
                setIsMintButtonActive(true);
            }
        }
    }, [collectionData.phases]);


    const fetchNft = async () => {
        const moduleAddress = "0x35d780fc7ebfcb968aff429a742aa3839fd9c7e3c3edbbf7ca56559bef59487b";
        try {
            const nftResource = await aptos.getAccountResource(
                {
                    accountAddress:nftAddress!,
                    resourceType:`${moduleAddress}::test::CollectionV1Data`
                }
            );

            setCollectionData(nftResource);


            axios.get(`${nftResource.uri}`)
                .then(response => {
                    setUriData(response.data);
                })
                .catch(err => console.log(err));

        } catch (e: any) {
            console.log(e);
        }
    };

    const countOfMinted = async () => {
        if (!account) return [];
        const res = await axios.post("https://api.mainnet.aptoslabs.com/v1/view", {
            "function": "0x35d780fc7ebfcb968aff429a742aa3839fd9c7e3c3edbbf7ca56559bef59487b::test::get_user_amount_of_mint",
            "type_arguments": [],
            "arguments": [nftAddress, account?.address]
        });

        setMintedNft(res.data[0]);
    };

    const Mint = async () => {
        if (!account) {
            toastError("Connect Wallet");

            return;
        }
        setTransactionInProgress(true);

        // @ts-ignore
        const collectionAddress: AccountAddress = new AccountAddress(arrayify(nftAddress));
        const amount: U64 = new U64((1));
        const value: U64 = new U64((collectionData.phases[0].mint_price));
        const moduleAddress = "0x35d780fc7ebfcb968aff429a742aa3839fd9c7e3c3edbbf7ca56559bef59487b";

        const transaction:InputTransactionData = {
            data: {
                function:`${moduleAddress}::test::mint`,
                functionArguments:[
                    collectionAddress, amount, value
                ]
            }
        }
        try {
            const response = await signAndSubmitTransaction(transaction);
            const res = await aptos.waitForTransaction({transactionHash:response.hash});
            if(res.success) {
                toastSuccess("+20 points");
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            setTransactionInProgress(false);
        }
    };

    const formatTime = (timeInSeconds: number) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <MainWrapper heading="Mint collection">
            <ToastContainer style={{ width: "330px" }} />
                    <div className="info-wrapper">
                        <div className="info-wrapper-img">
                            <img src={uriData?.image} alt="collection"/>
                        </div>

                        <div className="main-info">
                            <div className="main-info-heading">
                                <span>{collectionData.collection_name}</span>

                                <div className="user-links">
                                    {uriData?.discord ? <Link to={uriData.discord}><Discord/></Link> : ""}
                                    {uriData?.twitter ? <Link to={uriData.twitter}><Twitter/></Link> : ""}
                                    {uriData?.website ? <Link to={uriData.website}><OtherLink/></Link> : ""}
                                </div>
                            </div>

                            <span className="main-info-desc">{collectionData.collection_description}</span>

                            <div className="main-info-details">
                                <div className="main-info-detail">
                                    <span>Supply</span>
                                    <span className="main-info-detail-value">{collectionData.phases[0].minted} / {collectionData.phases[0].mint_limit}</span>
                                </div>
                                <div className="main-info-detail">
                                    <span>Price</span>
                                    <span className="main-info-detail-value">{collectionData.phases[0].mint_price / 100000000} APT</span>
                                </div>
                                <div className="main-info-detail">
                                    <span>Limit per user</span>
                                    <span className="main-info-detail-value">{collectionData.phases[0].mints_per_user} NFT</span>
                                </div>
                            </div>

                            {/* Выводим таймер, если не прошло 10 минут */}
                            {remainingTime !== null && (
                                <div>
                                    <p>Time left until mint: {formatTime(remainingTime)}</p>
                                </div>
                            )}

                            <button
                                className={`
                                    ${!isMintButtonActive || collectionData.phases[0].minted === collectionData.phases[0].mint_limit ||
                                    mintedNft === collectionData.phases[0].mints_per_user ? 
                                    "mint-btn disabled" : "mint-btn"}`
                                }
                                onClick={Mint}
                                disabled={
                                    !isMintButtonActive || collectionData.phases[0].minted === collectionData.phases[0].mint_limit ||
                                    mintedNft === collectionData.phases[0].mints_per_user
                                }
                            >
                                Mint (+20 points)
                            </button>
                        </div>
                    </div>

                    <img src={rocketOnStart} className="progressbar" alt="progressbar"/>
        </MainWrapper>

    );
};

export default MintBody;
