import React from 'react';
import { Route, Routes } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import Home from "./pages/Home";
import CreatePage from "./pages/Create";
import Mint from "./pages/Mint";
import MintApstation from "./pages/MintApstation";
import Profile from "./pages/Profile";

import "@aptos-labs/wallet-adapter-ant-design/dist/index.css";
import 'react-toastify/dist/ReactToastify.css';
import "./styles/global.scss";

function App() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    console.log(isTabletOrMobile);
    return (
        <>
        {isTabletOrMobile ?
            <div style={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center"}}>
                <span style={{fontSize: "40px", padding: "1rem", textAlign: "center"}}>
                    Please switch to the desktop version
                </span>
            </div>
            :
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create" element={<CreatePage />} />
                <Route path="/mint/:nftAddress" element={<Mint />} />
                <Route path="/mint-apstation" element={<MintApstation />} />
                <Route path="/profile" element={<Profile />} />
            </Routes>
        }
        </>
    );
}

export default App;
